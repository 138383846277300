let mainStoreModule = false;

//let context = require.context('@/overrides/client/store/', true, /\.js/);

let context = require.context(
  "@/",
  true,
  /\/overrides\/client\/store\/index\.js/
);
context.keys().forEach((key) => {
  if ("./overrides/client/store/index.js" === key) {
    mainStoreModule = context(key);
  }
});

if (!mainStoreModule) {
  mainStoreModule = require("@/client/store/store.js");
}

export default mainStoreModule.default;
